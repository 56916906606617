<template>
  <div v-if="$store.state.me.role !== 'partner'" class="lsg-cabinet">
    <div class="main__header">
      <div class="main__header__item left"></div>
      <div class="main__header__item">
        <h1>{{ title }}</h1>
      </div>
      <div class="main__header__item right">
        <button v-if="logs.length" @click="downloadLogs" class="btn">Скачать логи</button>
      </div>
    </div>
    <div class="main__content">
      <form class="lk" autocomplete="off" @submit.prevent="saveBase">
        <div class="lk__inputs">
          <div class="lk__field">
            <label>Ответственный:</label>
            <v-select :value="{ label: fields.responsible_user_name, value: fields.responsible_user_id }" :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))" placeholder="" @input="onUserChange">
              <template slot="no-options">Нет результатов</template>
              <template #open-indicator="{ attributes }">
                <div v-bind="attributes">
                  <DownIcon/>
                </div>
              </template>
            </v-select>
          </div>
          <div class="lk__field">
            <label>Тег:</label>
            <input type="text" v-model="fields.tag" placeholder="Введите тег">
          </div>
          <div class="lk__field">
            <label>Настройки:</label>
            <div class="lk__field">
              <label>
                <input type="checkbox" v-model="fields.options.checkMobilePhone" style="margin-right: 10px">
                <span>Наличие мобильных номеров (регулярное выражение: /^79/)</span>
              </label>
              <label>
                <input type="checkbox" v-model="fields.options.fixInn" style="margin-right: 10px">
                <span>Исправление ИНН (добавление в начало ИНН недостающих нулей)</span>
              </label>
            </div>
          </div>
          <div class="lk__field">
            <vue-dropzone
              ref="base"
              id="base"
              @vdropzone-thumbnail="thumbGenerated"
              @vdropzone-files-added="filePicked"
              :includeStyling="false"
              useCustomSlot
              :options="dropzoneOptions">
              <div class="lk__dz">
                <i>
                  <PaperClip/>
                </i>
                <span>{{ fields.filename ? fields.filename : 'Выбрать файл' }}</span>
              </div>
            </vue-dropzone>
          </div>
          <div class="buttons">
            <button type="submit" class="btn">Загрузить</button>
            <button @click.prevent="resetData" class="btn">Очистить</button>
          </div>
        </div>
        <div class="lk__back">
          <CompanyBack/>
        </div>
      </form>
      <div v-if="logs.length" class="base-logs">
        <div v-for="(message, index) in logs" :key="index" class="base-logs__message">
          <div class="message__number">{{ index + 1 }}</div>
          <div class="message__text">{{ message }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyBack from '@/components/svg/CompanyBack'
import DownIcon from '@/components/svg/DownIcon'
import vue2Dropzone from 'vue2-dropzone'
import PaperClip from '@/components/svg/PaperClip'
import * as XLSX from 'xlsx'
import UPLOAD_BASE from '@/graphql/mutations/UploadBase.gql'

import GraphQLHelper from '@/helpers/GraphQLHelper'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'AddCompany',
  components: {
    DownIcon,
    vueDropzone: vue2Dropzone,
    PaperClip,
    CompanyBack
  },
  data () {
    return {
      title: 'Загрузить базу',
      disabled: true,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 120,
        maxFiles: 1
      },
      fields: {
        tag: null,
        responsible_user_id: '',
        responsible_user_name: '',
        filename: null,
        file: null,
        xlData: null,
        options: {
          fixInn: false,
          checkMobilePhone: false
        }
      },
      logs: [],
      loading: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$store.state.loadedPages.push({ title: this.$route.meta.title, route: this.$route.name })
    })
  },
  created () {
    if (this.$store.state.me.role !== 'admin') {
      this.$router.push({ name: 'home' })
    }
    this.fields.responsible_user_id = this.$store.state.me._id
    this.fields.responsible_user_name = this.$store.state.me.name
  },
  methods: {
    downloadLogs () {
      const blob = new Blob(this.logs.map(elem => `${elem}\r\n`), { type: 'text/javascript' })
      const url = window.URL.createObjectURL(blob)

      let link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'logs.txt')
      document.body.appendChild(link)
      link.click()
      link.remove()
    },
    saveBase () {
      this.loading = true
      this.$notify({
        group: 'lsg-notify',
        text: 'Отправка данных...',
        duration: -1
      })
      this.$apollo.mutate({
        mutation: UPLOAD_BASE,
        variables: {
          input: {
            responsible_user_id: this.fields.responsible_user_id,
            xlData: this.fields.xlData,
            tag: this.fields.tag,
            options: this.fields.options
          }
        },
        update: (_, { data }) => {
          if (data && data.uploadBase) {
            this.logs = data.uploadBase
            this.loading = false
            this.$notify({
              group: 'lsg-notify',
              clean: true
            })
          }
        }
      }).catch(error => {
        this.loading = false
        this.$notify({
          group: 'lsg-notify',
          clean: true
        })
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    resetData () {
      this.fields.file = null
      this.fields.filename = null
      this.fields.xlData = null
      this.fields.tag = null
      this.fields.responsible_user_id = this.$store.state.me._id
      this.fields.responsible_user_name = this.$store.state.me.name
      this.fields.options.fixInn = false
      this.fields.options.checkMobilePhone = false
      this.logs = []
    },
    thumbGenerated (e) {
      this.fields.filename = e.name
    },
    async filePicked (e) {
      this.fields.file = e[0]
      this.fields.filename = e[0].name
      const fileBlob = await this.fileToBlob(this.fields.file)
      const fileJSON = this.XLToJSON(fileBlob)
      this.fields.xlData = fileJSON
    },
    fileToBlob (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    XLToJSON (data) {
      let workbook = XLSX.read(data, { type: 'array' })
      let sheetName = workbook.SheetNames[0]
      let worksheet = workbook.Sheets[sheetName]
      return XLSX.utils.sheet_to_json(worksheet)
    },
    onUserChange (e) {
      if (e) {
        this.fields.responsible_user_id = e.value
        this.fields.responsible_user_name = e.label
      } else {
        this.fields.responsible_user_id = null
        this.fields.responsible_user_name = null
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .buttons {
    display flex
    flex-direction row
    justify-content space-between

    width 100%
  }
  .base-logs {
    display flex
    flex-direction column
    justify-content space-between
    width 100%
    padding 10px
    border-radius 10px

    background-color #1d1f21

    &__message {
      display flex
      flex-direction row
      justify-content flex-start
    }
  }

  .message {
    &__number, &__text {
      color #c5c8c6
    }

    &__number {
      width 30px
    }
  }
</style>
